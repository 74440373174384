<header>
    <div class="header-container">
        <div class="header-aot-logo">
            <img src="../../../assets/images/aot-logo.png" class="header-aot-logo-image" alt=""
                [routerLink]="['presentacion']">
            <img src="../../../assets/images/aot-logo.png" class="header-aot-logo-image-mobile"
                (click)="toggleSideBarMenu()">

        </div>
        <div class="header-links" *ngIf="!isLoged">
            <a [routerLink]="'/'" class="main-link" id="main-link-">INICIO</a>
            <a [routerLink]="'/contacto'" class="main-link" id="main-link-contacto">CONTACTO</a>
        </div>
        <div class="header-links" *ngIf="isLoged">
            <a [routerLink]="['contacto-aot']" class="main-link" id="main-link-contacto">Contacto AOT
                </a>
            <a [routerLink]="['/emails']" class="main-link" id="main-link-email">{{TEXTS.CORREO}}</a>
            <a class="main-link ">Noticias</a>
            <a class="main-link ">Ayuda AOT</a>
        </div>

        <div class="header-logos">
            <img src="../../../assets/images/gesplan-logo.png" alt="">
            <img src="../../../assets/images/gobiernodecanarias.svg" alt="">
            <figure class="enter-button" *ngIf="!isLoged" (click)="openLoginModal()">
                <img src="../../../assets/images/entrar.svg">
                <figcaption>Entrar</figcaption>
            </figure>
            <figure class="enter-button" *ngIf="isLoged" (click)="logOut()">
                <img src="../../../assets/images/salir.svg">
                <figcaption>Salir</figcaption>
            </figure>
        </div>

    </div>

    <app-login-modal></app-login-modal>

</header>