import { Global } from "./global";

export const MATERIAS_TRANSVERSALES = [
    {
        bloque: 1,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}territorio_vida.jpg`,
        categoria: 'Concienciaciçon ciudadana',
        titulo: 'Un Territorio para la Vida',
        descripcion: '"En coordinación con las oficinas técnicas se establecen los temas y prioridades de las acciones formativas, tanto desde el punto de vista teórico como el práctico. Se imparten telemáticamente o bien en salas o espacios habilitados para tal efecto.',
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}territorio_vida.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}territorio_vida.pdf`,


    },
    {
        bloque: 2,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}sembrando_ciudades_saludables.jpg`,
        categoria: 'Concienciaciçon ciudadana',
        titulo: 'Sembrando Ciudades Saludables',
        descripcion: '"En coordinación con las oficinas técnicas se establecen los temas y prioridades de las acciones formativas, tanto desde el punto de vista teórico como el práctico. Se imparten telemáticamente o bien en salas o espacios habilitados para tal efecto.',
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}sembrando_ciudades_saludables.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}1_1_que_es_un_proyecto.mp4`,

    },
    {
        bloque: 3,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}encuentros_placeta.jpg`,
        categoria: 'Proceso participativo',
        titulo: 'Encuentros en la placeta',
        descripcion: '"En coordinación con las oficinas técnicas se establecen los temas y prioridades de las acciones formativas, tanto desde el punto de vista teórico como el práctico. Se imparten telemáticamente o bien en salas o espacios habilitados para tal efecto.',
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}encuentros_placeta.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}encuentros_placeta.mp4`,


    },
    {
        bloque: 4,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}aot_proc_particip_municipales.jpg`,
        categoria: 'Proceso participativo',
        titulo: 'AOT en los procesos participativos municipales',
        descripcion: '"En coordinación con las oficinas técnicas se establecen los temas y prioridades de las acciones formativas, tanto desde el punto de vista teórico como el práctico. Se imparten telemáticamente o bien en salas o espacios habilitados para tal efecto.',
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}aot_proc_particip_municipales.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}aot_proc_particip_municipales.mp4`,

    }
];
