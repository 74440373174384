<div class="who-menu" id="who-menu">
    <a class="secondary-link who-menu-link selected" (click)="scrollIntoView('objeto-del-programa')"
        id="who-program">Objeto del
        programa</a>
    <a class="secondary-link who-menu-link" (click)="scrollIntoView('lineas-de-actuacion')" id="who-lines">Líneas de
        actuación</a>
    <a class="secondary-link who-menu-link " (click)="scrollIntoView('equipo-multidisciplinar')"
        id="who-multiple">Equipo
        multidisciplinar</a>
</div>
<div class="who" id="who">

    <h2 id="objeto-del-programa">Objeto del programa</h2>

    <div class="who-info">
        <p>El objeto del programa de apoyo a las oficinas técnicas es facilitar a los ayuntamientos de los
            <strong>municipios menores de 100.000 habitantes y los cabildos de las islas no
                capitalinas</strong>, el apoyo necesario para el óptimo desarrollo de las diferentes actividades que son
            competencia de las oficinas técnicas
            enmarcadas en las líneas de actuación del programa.
        </p>
        <a href="https://www.territoriocanario.org/ORDEN_151_2023_AOT_24-25_.pdf" target="_blank"><button
                class="primary-button">Más información</button></a>
    </div>


    <h2 id="lineas-de-actuacion">Líneas de actuación</h2>

    <div class="who-lines">

        <div class="who-lines-info">

            <p>Mediante orden nº 151/2023 de fecha 26 de diciembre de 2023, <strong>la Consejería de Política
                    Territorial, Cohesión Territorial y Aguas</strong> encomienda a
                <strong>Gestión y Planeamiento Territorial Y Medioambiental, S.A. (GESPLAN)</strong>el:
            </p>

            <small>"PROGRAMA DE APOYO A LAS CORPORACIONES LOCALES E INSULARES DE LAS ISLAS DE LA PALMA, LA GOMERA, EL
                HIERRO, LANZAROTE Y
                FUERTEVENTURA, ASÍ COMO LAS CORPORACIONES LOCALES DE MENOS DE 100.000 HABITANTES DE LAS ISLAS DE
                TENERIFE Y GRAN
                CANARIA, PARA IMPULSAR EL DESARROLLO DE LAS ACTIVIDADES DE LAS OFICINAS TÉCNICAS, Y LA CONCIENCIACIÓN
                CIUDADANA EN
                MATERIA DE URBANISMO"</small>

        </div>
    </div>

    <div class="line">
        <img src="../../../assets/images/acciones_formativas.jpg" alt="">
        <div class="line-info">
            <h3>Foros de aprendizaje</h3>
            <p>En coordinación con las oficinas técnicas, se
                establecen las posibles acciones formativas
                de interés. Pueden tener diferentes
                formatos, aunque, principalmente, se
                conciben como encuentros de intercambio
                de experiencias sobre un tema concreto.</p>
        </div>
    </div>

    <div class="line reversed">
        <img src="../../../assets/images/asesoramiento_tecnico.jpg" alt="">
        <div class="line-info">
            <h3>Asesoramiento técnico/jurídico</h3>
            <p>El objetivo de esta línea es dotar a las
                oficinas técnicas de ayuntamientos y
                cabildos del apoyo necesario para el correcto desarrollo de sus actividades,
                prestando asesoramiento en todos los
                temas que puedan necesitar. Se trata, por
                tanto, de poner a su servicio un equipo
                multidisciplinar con experiencia con la
                finalidad de reforzar su capacidad en
                aquellos aspectos técnicos y jurídicos que
                sean requeridos y estén relacionados con el
                urbanismo y la ordenación del territorio.</p>
        </div>
    </div>

    <div class="line">
        <img src="../../../assets/images/apoyo_redaccion_ordenanzas.jpg" alt="">
        <div class="line-info">
            <h3>Apoyo en la redacción de ordenanzas y de
                otros modelos de documentos</h3>
            <p>Esta línea consiste en elaborar modelos-
                propuesta de ordenanzas municipales
                relacionadas con el urbanismo o el territorio
                y otros modelos de documentos tales como
                solicitudes, informes técnicos y jurídicos,
                convenios, pliegos, guías, aclaraciones
                normativas y otros. A partir de éstos, las
                corporaciones locales deberán adaptar los
                distintos documentos a sus particularidades
                o necesidades en cada caso.</p>
        </div>
    </div>

    <div class="line reversed">
        <img src="../../../assets/images/apoyo_oficinas_tecnicas.jpg" alt="">
        <div class="line-info">
            <h3>Información y asesoramiento en materias
                transversales al urbanismo.</h3>
            <p>Esta línea tiene la finalidad de informar al
                personal técnico en materias transversales y
                fundamentales en el urbanismo y el
                planeamiento tales como la participación
                ciudadana, la igualdad de género, el cambio
                climático, agenda urbana, y otras.
                Asimismo, si las Administraciones lo
                demandaran, se podrá asesorar y ayudar a
                las mismas en la organización y desarrollo de
                procesos o acciones participativas que
                tengan en marcha o que tengan necesidad
                de llevar a cabo, para la toma de decisiones
                de cualquier tema urbanístico,
                medioambiental o de ordenación territorial,
                siendo previamente acordado con la
                Consejería y hasta agotar el límite
                presupuestado.</p>
        </div>
    </div>

    <div class="line">
        <img src="../../../assets/images/fondos.jpg" alt="">
        <div class="line-info">
            <h3>Información y asesoramiento sobre
                subvenciones</h3>
            <p>Para las entidades locales e insulares las
                subvenciones provenientes de distintas administraciones y entidades representan
                una excelente oportunidad de obtención de
                cofinanciación para llevar a cabo
                actuaciones interesantes. Con esta línea se
                pretende analizar las diferentes
                convocatorias de subvenciones y
                comunicarlo a las diferentes
                administraciones para que puedan optar a la
                financiación de las actuaciones que
                consideren.
                Asimismo, si las Administraciones requieren
                de un mayor asesoramiento y/o apoyo en la
                elaboración de la documentación técnica
                necesaria, con objeto de poder facilitar la
                labor a los ayuntamientos en la concurrencia
                a esta convocatoria, se podrá prestar este
                apoyo, siendo previamente acordado con la
                Consejería y hasta agotar el límite
                presupuestado.</p>
        </div>
    </div>

    <!--<div class="line reversed">
        <img src="../../../assets/images/igualdad.jpg" alt="">
        <div class="line-info">
            <h3>Asesoramiento relacionado con la incorporación de la participación ciudadana, la igualdad de género y el
                cambio climático en el planeamiento.</h3>
            <p>Esta línea de actuación se plantea con el objetivo de asesorar al personal técnico para la incorporación
                y fomento de la
                participación ciudadana, la igualdad de género y el cambio climático, que deberán tenerse en cuenta en
                el urbanismo y
                ordenación del territorio, de acuerdo al marco normativo actual.</p>
        </div>
    </div>


    <div class="line">
        <img src="../../../assets/images/concienciacion_ciudadana.jpg" alt="">
        <div class="line-info">
            <h3>Concienciación ciudadana</h3>
            <p>Esta línea de actuación se abordará desde la necesidad de fomentar la cultura del planeamiento. El
                objetivo es hacer
                entendible el urbanismo actual a la ciudadanía para que, con conocimiento, puedan posteriormente
                participar y opinar en
                procesos relacionados con el territorio.
            </p>
        </div>
    </div>

-->

    <h2 id="Equipo multidisciplinar" id="equipo-multidisciplinar">Equipo multidisciplinar</h2>

    <div class="who-multi">
        <div class="who-multi-info">

            <p>Para cumplir con el objeto del programa descrito, este cuenta con un equipo multidisciplinar con
                capacidad para abordar
                las diversas solicitudes que se reciben.
            </p>
            <p>El equipo cuenta con profesionales administrativos, ambientalistas, de arquitectura y urbanismo,
                economistas, de
                informática, de ingeniería en diseño industrial, de ingeniería civil, de ingeniería geomática, juristas
                y de sociología,
                con oficinas en Tenerife, Gran Canaria, Lanzarote y Fuerteventura.</p>

        </div>
    </div>

    <!--
    <div class="who-team">
        <div class="who-team-content tenerife">
            <div class="who-team-header"></div>
            <p>El equipo de la provincia de Santa Cruz de Tenerife se compone de doce profesionales: <strong>Seis (6)
                    arquitectos/as, una (1) arquitecta técnica y cinco (5) juristas.</strong></p>
        </div>
        <div class="who-team-content laspalmas">
            <div class="who-team-header"></div>
            <p>El equipo de la provincia de Las Palmas se compone de dieciocho profesionales: <strong>Dos (2)
                    administrativos/as, seis (6) arquitectas, una (1) economista, un (1) informático, un (1) ingeniero
                    técnico de diseño industrial, seis (6) juristas y una (1) socióloga.</strong></p>

        </div>
    </div>


-->

    <!--
    <div class="who-team">
        <div class="member">
            <div class="member-image">
            </div>
            <div class="member-description">
                <p class="member-description-name">
                    David Hidalgo Bouza
                </p>
                <p class="member-description-job">ARQUITECTA</p>
            </div>
        </div>
        <div class="member">
            <div class="member-image">
            </div>
            <div class="member-description">
                <p class="member-description-name">
                    David Hidalgo Bouza
                </p>
                <p class="member-description-job">ARQUITECTA</p>
            </div>
        </div>
        <div class="member">
            <div class="member-image">
            </div>
            <div class="member-description">
                <p class="member-description-name">
                    David Hidalgo Bouza
                </p>
                <p class="member-description-job">ARQUITECTA</p>
            </div>
        </div>
        <div class="member">
            <div class="member-image">
            </div>
            <div class="member-description">
                <p class="member-description-name">
                    David Hidalgo Bouza
                </p>
                <p class="member-description-job">ARQUITECTA</p>
            </div>
        </div>
        <div class="member">
            <div class="member-image">
            </div>
            <div class="member-description">
                <p class="member-description-name">
                    David Hidalgo Bouza
                </p>
                <p class="member-description-job">ARQUITECTA</p>
            </div>
        </div>
    </div>
-->

</div>