<div class="contact">
    <div class="contact-form">
        <form (ngSubmit)="onSubmit()" [formGroup]="contactoForm">
            <div class="contact-form-name">
                <div class="aot-input">
                    <label for="remitente">Nombre</label>
                    <input type="text" formControlName="remitente" #remitente required
                        placeholder='Introduzca su nombre'
                        [ngClass]="{'rojo': contactoForm.get('remitente')?.touched && contactoForm.get('remitente')?.errors?.['required'], 'verde': contactoForm.get('remitente')?.valid}">
                    <div *ngIf="contactoForm.get('remitente')?.touched && contactoForm.get('remitente')?.errors?.['required']"
                        style="color: rgb(169, 72, 47)">Este campo es obligatorio
                    </div>

                </div>
                <div class="aot-input">
                    <label for="email">Email</label>
                    <input type="text" id="email" name="email" formControlName="email" placeholder='Introduzca el email'
                        required
                        [ngClass]="{'rojo': (contactoForm.get('email')?.touched && contactoForm.get('email')?.errors?.['required']) || contactoForm.get('email')?.errors?.['pattern'], 'verde': contactoForm.get('email')?.valid}">
                    <div *ngIf="contactoForm.get('email')?.touched && contactoForm.get('email')?.errors?.['required']"
                        style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                    <div *ngIf="contactoForm.get('email')?.errors?.['pattern']" style="color: rgb(169, 72, 47)">No
                        tiene el formato adecuado</div>
                </div>
            </div>

            <div class="aot-input">
                <label for="mensaje">Mensaje</label>
                <textarea placeholder='Introduzca el mensaje' type="text-area" formControlName="mensaje" #mensaje
                    [ngClass]="{'rojo': contactoForm.get('mensaje')?.touched && contactoForm.get('mensaje')?.errors?.['required'], 'verde': contactoForm.get('mensaje')?.valid}"></textarea>
                <div *ngIf="contactoForm.get('mensaje')?.touched && contactoForm.get('mensaje')?.errors?.['required']"
                    style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
            </div>
            <div class="contact-form-button">
                <button class="primary-button" type="submit">Enviar Mensaje</button>
            </div>
        </form>

        <!--<div class="contact-form-button" (click)="onSubmit()">
            <button class="primary-button">Enviar Mensaje</button>
        </div>-->
    </div>
</div>



<app-login-modal></app-login-modal>

<app-footer></app-footer>