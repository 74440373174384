<div class="aot-main-container">
    <div class="aot-register-container" [ngStyle]="{'width.%': size}">
        <header *ngIf="mostrarTitulo">
            <h1>Registro</h1>
        </header>
        <form (ngSubmit)="onSubmit()" [formGroup]="registroForm" *ngIf="registroForm">

            <div class="register-content">
                <h2 [ngStyle]="{ 'margin-top': mostrarTitulo ? '50px': '0px' }">Información personal</h2>
                <div class="register-content-personal-info">
                    <div class="aot-input">
                        <label for="nombre">Nombre</label>
                        <input type="text" id="nombre" name="nombre" formControlName="nombre"
                            placeholder='Introduzca el nombre' required
                            [ngClass]="{'rojo': registroForm.get('nombre')?.touched && registroForm.get('nombre')?.errors?.['required'], 'verde': registroForm.get('nombre')?.valid}">
                        <div *ngIf="registroForm.get('nombre')?.touched && registroForm.get('nombre')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>

                    </div>

                    <div class="aot-input">
                        <label for="apellidos">Apellidos</label>
                        <input type="text" id="apellidos" name="apellidos" formControlName="apellidos"
                            placeholder='Introduzca los apellidos'
                            [ngClass]="{'rojo': registroForm.get('apellidos')?.touched && registroForm.get('apellidos')?.errors?.['required'], 'verde': registroForm.get('apellidos')?.valid}">
                        <div *ngIf="registroForm.get('apellidos')?.touched && registroForm.get('apellidos')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>

                    </div>

                </div>

                <div class="register-content-personal-info">
                    <div class="aot-select col-3">
                        <label for="seccion_id">Área / Sección</label>
                        <select name="seccion_id" id="seccion_id" formControlName="seccion_id"
                            [ngClass]="{'rojo': registroForm.get('seccion_id')?.touched && registroForm.get('seccion_id')?.errors?.['required'], 'verde': registroForm.get('seccion_id')?.valid}">
                            <option value='' disabled selected hidden>Selecciona una opción</option>
                            <option value={{seccion.id}} *ngFor="let seccion of secciones">{{seccion.seccion}}</option>
                        </select>
                        <div *ngIf="registroForm.get('seccion_id')?.touched && registroForm.get('seccion_id')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>


                        <div class="aot-input">
                            <input type="text" placeholder="Especifica el nombre del Área o sección"
                                *ngIf="registroForm.get('seccion_id')?.value === '24' || registroForm.get('seccion_id')?.value === 24"
                                formControlName="otra_seccion">
                        </div>

                    </div>
                    <div class="aot-select col-3">
                        <label for="cargo_id">Puesto / Cargo</label>
                        <select name="cargo_id" id="cargo_id" formControlName="cargo_id"
                            [ngClass]="{'rojo': registroForm.get('cargo_id')?.touched && registroForm.get('cargo_id')?.errors?.['required'], 'verde': registroForm.get('cargo_id')?.valid}">
                            <option value='' disabled selected hidden>Selecciona una opción</option>
                            <option value={{cargo.id}} *ngFor="let cargo of cargos">{{cargo.cargo}}</option>
                        </select>
                        <div *ngIf="registroForm.get('cargo_id')?.touched && registroForm.get('cargo_id')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>

                        <div class="aot-input">
                            <input type="text" placeholder="Especifica el nombre del puesto o cargo"
                                *ngIf="registroForm.get('cargo_id')?.value === '11' || registroForm.get('cargo_id')?.value === 11"
                                formControlName="otro_cargo">
                        </div>
                    </div>
                    <div class="aot-select col-3">
                        <label for="profesion_id">Profesión</label>
                        <select name="profesion_id" id="profesion_id" formControlName="profesion_id"
                            [ngClass]="{'rojo': registroForm.get('profesion_id')?.touched && registroForm.get('profesion_id')?.errors?.['required'], 'verde': registroForm.get('profesion_id')?.valid}">
                            <option value='' disabled selected hidden>Selecciona una opción</option>
                            <option value={{profesion.id}} *ngFor="let profesion of profesiones">{{profesion.profesion}}
                            </option>

                        </select>
                        <div *ngIf="registroForm.get('profesion_id')?.touched && registroForm.get('profesion_id')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>

                        <div class="aot-input">
                            <input type="text" placeholder="Especifica el nombre de la profesión"
                                *ngIf="registroForm.get('profesion_id')?.value === '9' || registroForm.get('profesion_id')?.value === 9"
                                formControlName="otra_profesion">
                        </div>
                    </div>
                </div>

                <h2>Datos de la corporación</h2>
                <div class="register-content-corp">
                    <div class="aot-select col-4">
                        <label for="corporacion_id">Corporación</label>
                        <select name="corporacion_id" id="corporacion_id" formControlName="corporacion_id"
                            [ngClass]="{'rojo': registroForm.get('corporacion_id')?.touched && registroForm.get('corporacion_id')?.errors?.['required'], 'verde': registroForm.get('corporacion_id')?.valid}">
                            <option value='' selected hidden>Selecciona una opción
                            </option>
                            <option value={{corporacion.id}} *ngFor="let corporacion of corporaciones">
                                {{corporacion.corporacion}}</option>

                        </select>
                        <div *ngIf="registroForm.get('corporacion_id')?.touched && registroForm.get('corporacion_id')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                    </div>

                    <div class="aot-select col-4">
                        <label for="provincia_id">Provincia</label>
                        <select name="provincia_id" id="provincia_id" formControlName="provincia_id"
                            [ngClass]="{'rojo': registroForm.get('provincia_id')?.touched && registroForm.get('provincia_id')?.errors?.['required'], 'verde': registroForm.get('provincia_id')?.valid}">
                            <option value='' disabled selected hidden>Selecciona una opción</option>
                            <option value={{provincia.id}} *ngFor="let provincia of provincias">
                                {{provincia.provincia}}</option>

                        </select>
                        <div *ngIf="registroForm.get('provincia_id')?.touched && registroForm.get('provincia_id')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                    </div>

                    <div class="aot-select col-4">
                        <label for="isla_id">Isla</label>
                        <select name="isla_id" id="isla_id" formControlName="isla_id"
                            [ngClass]="{'rojo': registroForm.get('isla_id')?.touched && registroForm.get('isla_id')?.errors?.['required'], 'verde': registroForm.get('isla_id')?.valid}">
                            <option value='' disabled selected hidden>Selecciona una opción</option>
                            <option value={{isla.id}} *ngFor="let isla of islas">
                                {{isla.isla}}</option>

                        </select>
                        <div *ngIf="registroForm.get('isla_id')?.touched && registroForm.get('isla_id')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                    </div>


                    <div class="aot-select col-4">
                        <label for="municipio_id">Municipio</label>
                        <select name="municipio_id" id="municipio_id" formControlName="municipio_id"
                            [ngClass]="{'rojo': registroForm.get('municipio_id')?.touched && registroForm.get('municipio_id')?.errors?.['required'], 'verde': registroForm.get('municipio_id')?.valid}">
                            <option value='' disabled selected hidden>Selecciona una opción</option>
                            <option value={{municipio.id}} *ngFor="let municipio of municipios">
                                {{municipio.municipio}}</option>

                        </select>
                        <div *ngIf="registroForm.get('municipio_id')?.touched && registroForm.get('municipio_id')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                    </div>
                </div>
                <h2>Datos de contacto</h2>
                <div class="register-content-personal-info">
                    <div class="aot-input">
                        <label for="telefono">Teléfono</label>
                        <input type="text" id="telefono" name="telefono" formControlName="telefono"
                            placeholder='Introduzca el teléfono'>
                    </div>
                    <div class="aot-input">
                        <label for="extension">Extensión</label>
                        <input type="text" id="extension" name="extension" formControlName="extension"
                            placeholder='Introduzca la extensión'>
                    </div>
                    <div class="aot-input">
                        <label for="email">Email</label>
                        <input type="text" id="email" name="email" formControlName="email"
                            placeholder='Introduzca el email' required
                            [ngClass]="{'rojo': (registroForm.get('email')?.touched && registroForm.get('email')?.errors?.['required']) || registroForm.get('email')?.errors?.['pattern'], 'verde': registroForm.get('email')?.valid}">
                        <div *ngIf="registroForm.get('email')?.touched && registroForm.get('email')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                        <div *ngIf="registroForm.get('email')?.errors?.['pattern']" style="color: rgb(169, 72, 47)">No
                            tiene el formato adecuado</div>

                    </div>
                    <div class="aot-input">
                        <label for="movil">Móvil</label>
                        <input type="number" id="movil" name="movil" formControlName="movil"
                            placeholder='Introduzca el nº de móvil'>
                    </div>
                </div>

                <h2>¿Desea recibir avisos?</h2>
                <div class="register-content-newsletter">
                    <div class="aot-input-checkbox">
                        <input type="checkbox" id="newsletters" name="newsletters" formControlName="newsletters">
                        <label for="checkbox">Avisos de Newsletter</label>
                    </div>

                    <div class="aot-input-checkbox">
                        <input type="checkbox" id="subvenciones" name="subvenciones" formControlName="subvenciones">
                        <label for="checkbox">Avisos de Subvenciones</label>
                    </div>

                    <div class="aot-input-checkbox">
                        <input type="checkbox" id="sentencias" name="sentencias" formControlName="sentencias">
                        <label for="checkbox">Avisos de Sentencias de interés</label>
                    </div>
                </div>

                <h2>Contraseña</h2>
                <div class="register-content-password">
                    <strong *ngIf="esUsuarioLogeado">Si desea cambia su contraseña pinche <a
                            (click)="cambiarPassword()">aquí</a></strong>
                    <div class="password-container" *ngIf="!esUsuarioLogeado">
                        <input type="password" placeholder="Contraseña" id="passwordRegistro" name="password"
                            formControlName="password" [ngClass]="{
                                'rojo': (registroForm.get('password')?.touched && registroForm.get('password')?.invalid),
                                'verde': registroForm.get('password')?.valid
                            }">
                        <img src="../../../assets/images/ojo.svg" id="eye"
                            (click)="mostrarPasswordIntroducidoPorElUsuario()">
                        <div *ngIf="registroForm.get('password')?.touched && registroForm.get('password')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                        <div *ngIf="registroForm.get('password')?.errors?.['pattern']" style="color: rgb(169, 72, 47)">
                            La contraseña debe de tener entre 8 y 20 caracteres, incluir al menos una letra mayúscula,
                            un número
                            y un carácter especial
                        </div>
                    </div>

                    <div class="password-container" *ngIf="!esUsuarioLogeado">
                        <input type="password" placeholder="Repetir contraseña" id="repetirPasswordRegistro"
                            name="password_confirm" formControlName="password_confirm" [ngClass]="{
                                'rojo': registroForm.hasError('passwordsNotMatch') && (registroForm.get('password_confirm')?.dirty || registroForm.get('password_confirm')?.touched),
            'verde': !registroForm.hasError('passwordsNotMatch') && registroForm.get('password_confirm')?.valid
                            }">
                        <img src="../../../assets/images/ojo.svg" id="eye"
                            (click)="mostrarPasswordIntroducidoPorElUsuario()">
                        <div *ngIf="registroForm.get('password_confirm')?.touched && registroForm.get('password_confirm')?.errors?.['required']"
                            style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                        <div *ngIf="registroForm.hasError('passwordsNotMatch') && (registroForm.get('password_confirm')?.dirty || registroForm.get('password_confirm')?.touched)"
                            style="color: rgb(169, 72, 47)">
                            Las contraseñas no coinciden
                        </div>

                    </div>



                </div>


                <div class="register-content-policy">
                    <div class="aot-input-checkbox" *ngIf="!esUsuarioLogeado">
                        <input type="checkbox" id="privacidad" name="privacidad" formControlName="privacidad"
                            [ngClass]="{'rojo': registroForm.get('privacidad')?.errors?.['required'], 'verde': registroForm.get('privacidad')?.valid}">
                        <label for="checkbox"><a [routerLink]="['/politica-de-privacidad']" target="_blank">He leído y
                                acepto la política de privacidad</a></label>
                    </div>
                    <div class="aot-input-checkbox" *ngIf="!esUsuarioLogeado">
                        <input type="checkbox" id="desactivar" name="desactivar" formControlName="desactivar"
                            [ngClass]="{'rojo': registroForm.get('desactivar')?.errors?.['required'], 'verde': registroForm.get('desactivar')?.valid}">
                        <label for="checkbox">Usted adquiere el compromiso de desactivar su cuenta cuando deje de formar
                            parte de la corporación</label>
                    </div>

                </div>
            </div>
        </form>
        <div class="register-content-buttons">
            <div>
                <strong>Después de 6 meses de inactividad, recibirá un correo para verificar su cuenta. <br>
                    Si no realiza la verificación, tras un año de inactividad, su cuenta será desactivada.
                </strong>
            </div>

            <div style="width: 50%; display: flex; gap: 20px">
                <button class="delete-button" *ngIf="esUsuarioLogeado" (click)="eliminarUsuario()">Eliminar
                    cuenta</button>
                <button class="secondary-button" *ngIf="!esUsuarioLogeado" [routerLink]="['']">Cancelar</button>
                <!--<button class="primary-button" (click)="onSubmit()">Aceptar</button>-->
                <button class="primary-button" type="submit">Aceptar</button>
            </div>


        </div>
    </div>
</div>