<div class="concienciacion-ciudadana">
    <h2 class="concienciacion-ciudadana-seccion-titulo">Participando con AOT</h2>
    <div class="concienciacion-ciudadana-seccion-texto-explicativo">
        <p>Texto explicativo de la información publicada, nos lo envían
            (Vera o David) cuando tengan el texto</p>
    </div>

    @for (materiaTransversal of materiasTransverasles; track materiaTransversal.bloque){
    <div [class.revertido]="materiaTransversal.bloque % 2 === 0" class="concienciacion-ciudadana-seccion">
        <img src={{materiaTransversal.imagen}} alt="">
        <div class="concienciacion-ciudadana-seccion-info">
            <small>{{materiaTransversal.categoria}}</small>
            <h1>{{materiaTransversal.titulo}}</h1>
            <p>{{materiaTransversal.descripcion}}</p>

            <div class="concienciacion-ciudadana-seccion-iconos">
                <img src="../../../assets/images/pdf.svg" alt="">
                <img src="../../../assets/images/video.PNG" alt="" (click)="openVideo(materiaTransversal.urlVIDEO)">
            </div>
        </div>

    </div>
    }



    <h2 class="concienciacion-ciudadana-seccion-titulo">Mapa de participación</h2>
    <div class="concienciacion-ciudadana-seccion-texto-explicativo">
        <p>Mapeo de experiencias relacionadas con la participación ciudadana en materia de urbanismo impulsadas desde la
            administración pública en Canarias.</p>
    </div>

    <img class="imagen-provisional" src="../../../assets/images/imagen_provisional_2.png" alt="">


</div>