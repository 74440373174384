export const CONTACTOS_AOT = {

    RESPONSABLES: [
        {
            nombre: 'Cristina García Bouza',
            perfil: 'Arquitecta',
            correo: 'cgarbou@gesplan.es',
            telefono: '922887887 Ext.7216'
        },
        {
            nombre: 'Patricia González Fernández',
            perfil: 'Arquitecta',
            correo: 'pgonfer@gesplan.es',
            telefono: '636931958'
        },
    ],
    TECNICOS: [
        {
            nombre: 'David Hidalgo Pérez',
            perfil: 'Arquitecto',
            correo: 'dhidper@gesplan.es',
            telefono: '-'
        },
        {
            nombre: 'Enrique Hernández Pérez',
            perfil: 'Jurista',
            correo: 'pherper@gesplan.es',
            telefono: '679776734'
        },
        {
            nombre: 'Francisco J. Darias Vizcanino',
            perfil: 'Arquitecto',
            correo: 'fdarviz@gesplan.es',
            telefono: '636186578'
        },
        {
            nombre: 'Laura Francisco Rodríguez',
            perfil: 'Arquitecta',
            correo: 'lfrarod@gesplan.es',
            telefono: '676212615'
        },
        {
            nombre: 'Manuel Negrín Talavera',
            perfil: 'Jurista',
            correo: 'mnegtal@gesplan.es',
            telefono: '618131337'
        },
        {
            nombre: 'Nicolás Fajardo López',
            perfil: 'Jurista',
            correo: 'nfajlop@gesplan.es',
            telefono: '629841690'
        }, {
            nombre: 'Noelia Chinea Morales',
            perfil: 'Jurista',
            correo: 'nchimor@gesplan.es',
            telefono: '648811686'
        }, {
            nombre: 'Virginia Vera García',
            perfil: 'Arquitecta',
            correo: 'vvergar@gesplan.es',
            telefono: '-'
        },
    ]
}

export const CONTACTOS_AOT_LP = {

    RESPONSABLES: [
        {
            nombre: 'Eva Padrón Sánchez',
            perfil: 'Arquitecta',
            correo: 'epadsan@gesplan.es',
            telefono: '928301150 Ext.7151'
        },
        {
            nombre: 'Silvia Nakoura González',
            perfil: 'Arquitecta',
            correo: 'snakgon@gesplan.es',
            telefono: '928301150 Ext.7165'
        },
    ],
    TECNICOS: [
        {
            nombre: 'Ana Valerón Romero',
            perfil: 'Arquitecta',
            correo: 'avalrom@gesplan.es',
            telefono: '928435544'
        },
        {
            nombre: 'Alberto Reyes Eugenio',
            perfil: 'Jurista',
            correo: 'areyeug@gesplan.es',
            telefono: '928435488'
        },
        {
            nombre: 'Antonio del Castillo Olivares Navarro',
            perfil: 'Jurista',
            correo: 'acasoli@gesplan.es',
            telefono: '928301150 Ext. 7169'
        },
        {
            nombre: 'Claudia Cabrera Fernández',
            perfil: 'Arquitecta',
            correo: 'ccabfer@gesplan.es',
            telefono: '928435488'
        },
        {
            nombre: 'José María Fragoso Torres',
            perfil: 'Jurista',
            correo: 'jfrator@gesplan.es',
            telefono: '928301150 Ext.7170'
        },
        {
            nombre: 'Patricia de la Cruz Kuhnel',
            perfil: 'Jurista',
            correo: 'pcrukuh@gesplan.es',
            telefono: '928301150 Ext.7173'
        }, {
            nombre: 'Patricia García Brosa',
            perfil: 'Arquitecta',
            correo: 'pgarbro@gesplan.es',
            telefono: '928301150 Ext.7169'
        }, {
            nombre: 'Mónica Henriquez Santana',
            perfil: 'Arquitecta',
            correo: 'mhensan@gesplan.es',
            telefono: '928301150 Ext.7118'
        }, {
            nombre: 'Evelyn Sánchez Ortega',
            perfil: 'Jurista',
            correo: 'esanort@gesplan.es',
            telefono: '928301150 Ext.7139'
        },
    ]
}