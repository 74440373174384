import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const passwordPattern = (): RegExp => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,]).{8,20}$/;
}


export const comprobarCondicionesPassword = (password: string): boolean => {
    if (typeof password !== 'string') {
        return false;
    }

    // Verificar la longitud
    if (password.length < 8 || password.length > 20) {
        return false;
    }

    // Verificar al menos una mayúscula
    if (!/[A-Z]/.test(password)) {
        return false;
    }

    // Verificar al menos una minúscula
    if (!/[a-z]/.test(password)) {
        return false;
    }

    // Verificar al menos un carácter especial
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
        return false;
    }

    // Verificar al menos un número
    if (!/\d/.test(password)) {
        return false;
    }

    // Verificar que no contenga espacios en blanco
    if (/\s/.test(password)) {
        return false;
    }

    // Si pasa todas las condiciones, retorna true
    return true;
};

export const comprobarSiDosPasswordSonIguales = (password1: string, password2: string): boolean => {
    if (password1 !== password2) return false;
    return true;
}


export const matchpassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {


    let password = control.get('password');
    let confirmpassword = control.get('repetirPassword');
    if (password && confirmpassword && password?.value != confirmpassword?.value) {
        return {
            passwordmatcherror: true
        }
    }
    return null;
}

