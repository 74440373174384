import { Component, OnInit } from '@angular/core';
import { MATERIAS_TRANSVERSALES } from 'src/app/helpers/materias_transversales';

@Component({
  selector: 'app-concienciacion-ciudadana',
  templateUrl: './concienciacion-ciudadana.component.html',
  styleUrls: ['./concienciacion-ciudadana.component.css']
})
export class ConcienciacionCiudadanaComponent implements OnInit {

  public materiasTransverasles: any[] = [];


  constructor() {

    this.materiasTransverasles = [...MATERIAS_TRANSVERSALES];



  }
  ngOnInit(): void {
  }

  openVideo(videoName: string): void {
    window.open(`${videoName}`, '_blank');
  }


}
