<div class="user-request" id="{{solicitud?.id ||0}}">
    <div class="user-request-left">
        <div class="user-request-left-data">
            <p><strong [ngStyle]="{'color': solicitud!.estado.color}">Nº de solicitud: {{solicitud?.id ||''}}</strong>
            </p>
            <p><strong>Fecha de solicitud: </strong>{{formatDate(solicitud!.fecha)}}</p>
            <p *ngIf="solicitud?.estado_id! > 3"><strong>Fecha de finalización:
                </strong>{{formatDate(solicitud!.expediente.fecha_fin)}}</p>
        </div>
        <div class="user-request-left-resume">
            <div>
                <p><strong>Tipo de solicitud:
                    </strong>{{solicitud?.tematica?.tematica ||''}}</p>
                <p><strong>Solicita: </strong>{{solicitud?.solicita || ''}}</p>
            </div>

            <strong id="{{solicitud?.expediente?.id}}" *ngIf="solicitud?.expediente && solicitud?.estado_id! > 3"
                (click)="abrirPDF()" class="user-request-left-resume-download onlydesktop"><img
                    src="../../../assets/images/pdf.svg" alt="">
                {{solicitud?.expediente?.expediente}}</strong>
        </div>
    </div>
    <div class="user-request-right">
        <div class="chat" (click)="openChat(solicitud)">
            <img src="../../../assets/images/chat.svg" alt="" (click)="getMensajesDelExpediente()"
                [ngClass]="{'disabled_button': solicitud?.tematica?.id === 5 || solicitud?.estado!.id <2}">
        </div>
        <hr class="onlydesktop">
        <strong id="{{solicitud?.expediente?.id}}" *ngIf="solicitud?.expediente && solicitud?.estado_id! > 3"
            (click)="abrirPDF()" class="user-request-left-resume-download onlymobile"><img
                src="../../../assets/images/pdf.svg" alt="">
        </strong>
        <div class="download" (click)="abrirMasInfo(solicitud?.id ||0)">
            <img src="../../../assets/images/descarga.svg" alt="" id="download-image-{{solicitud?.id}}">
        </div>


    </div>
</div>

<div class="user-request-chat" id="{{solicitud?.id || 0}}-chat">
    <div class="user-request-chat-header">
        <h3>Nº de solicitud: {{solicitud?.id}}</h3>
        <button (click)="openChat(solicitud)">X</button>
    </div>
    <div class="user-request-chat-content" #contenido>
        <div class="mensaje-chat" *ngFor="let mensajeExpediente of mensajesExpediente"
            [ngClass]="{'contestado': !mensajeDelUsuario(mensajeExpediente)}">
            <img src="../../../assets//images/usuario.svg" alt="" class="mensaje-chat-icono">
            <div class="mensaje-chat-contenido">
                <div class="mensaje-chat-header">
                    <strong>
                        {{mensajeExpediente.usuario.nombre}}{{mensajeExpediente.usuario.apellidos}}</strong>
                </div>
                <div class="mensaje-chat-texto">
                    <p>{{mensajeExpediente.mensaje}}</p>
                </div>

            </div>

        </div>

    </div>
    <div class="user-request-chat-footer">
        <app-input [type]="'text'" [placeholder]="'Mensaje'" (inputTextValueEmitter)="nuevoMensaje = $event"
            #nuevoMensajeAlExpediente (keyup.enter)="enviarMensajeAlExpediente()"></app-input>
        <button class="primary-button" (click)="enviarMensajeAlExpediente()" [disabled]="nuevoMensaje === ''"
            [ngClass]="{'disabled_button': nuevoMensaje === ''}">Enviar</button>
    </div>
</div>

<div class="user-solicitud-masinfo" id="{{solicitud?.id || 0}}-masinfo">
    <div class="user-solicitud-masinfo-content">
        <p><strong style="color: var(--p-secondary)">Descripción:</strong></p>
        <p>{{solicitud?.descripcion}}</p>
    </div>
    <div class="solicitud-masinfo" *ngFor="let fichero of ficheros">
        <a target="_blank" href="{{urlFicheroAdjunto}}{{fichero.directorio}}{{fichero.adjunto}}">
            {{fichero.adjunto}}</a><img src="../../../assets/images/papelera_negra.svg" alt=""
            (click)="eliminarArchivoAdjuntoDeLaSolicitud(fichero.id)">
    </div>
    <div class="user-solicitud-masinfo-footer" *ngIf="solicitud?.tematica?.tematica !== 'Disponibles'">
        <input type="file" id="archivoASubir-{{solicitud?.id}}" style="display: none;"
            (change)="archivoSeleccionado($event)" accept=".doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf,.zip,.txt">

        <small>* Tamaño máximo de archivo 25Mb, Sólo se permite enviar un archivo, para enviar varios archivos
            comprímalo en formato .zip</small>
        <img src="../../../assets/images/add_adjunto.svg" alt="" id="imagenBoton" (click)="activarInputFile()"
            style="cursor: pointer;" title="Adjuntar archivo">
    </div>

</div>