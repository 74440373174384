<div class="aot-contact">
    <h2 class="aot-contact-reclamo">Estamos aquí para ayudarte: <span>Puedes ponerte en contacto con nuestro personal
            técnico o dirigirte
            al
            correo de <strong>AOT</strong>: </span><a href="mailto:{{emailDeContacto}}">{{emailDeContacto}}</a>
    </h2>
    <h2>Horario: <span>Lunes - Jueves: 08:00 a 15:15 | Viernes: 08:00 a 14:00</span></h2>
    <div class="aot-contact-island" id="tenerife" *ngIf="codigoIsla === 1">
        <div class="aot-contact-island-data">
            <h3>Provincia: <span class="aot-contact-island-data-province">Santa Cruz de Tenerife</span></h3>
            <span>Oficina: Avenida Tres de Mayo, 71, CP: 38005, Santa Cruz de Tenerife.</span>
            <span>Teléfono: 922887887</span>
        </div>

        <h2>Responsables de AOT</h2>

        <div class="aot-contact-boss">

            <div class="boss" *ngFor="let responsable of CONTACTOS_AOT_RESPONSABLES">
                <div class="boss-data">
                    <p class="boss-name">{{responsable.nombre}}</p>
                    <p class="boss-job">{{responsable.perfil.toLocaleUpperCase()}}</p>
                </div>
                <div class="boss-email">
                    <h3>Correo: </h3>
                    <a href="mailto:{{responsable.nombre}}">{{responsable.correo}}</a>
                </div>
                <div class="boss-phone">
                    <h3>Teléfono: </h3>
                    <a>{{responsable.telefono}}</a>
                </div>
            </div>
        </div>

        <h2>Personal técnico de AOT</h2>
        <div class="aot-contact-boss">


            <div class="boss" *ngFor="let tecnico of CONTACTOS_AOT_TECNICOS">
                <div class="boss-data">
                    <p class="boss-name">{{tecnico.nombre}}</p>
                    <p class="boss-job">{{tecnico.perfil.toLocaleUpperCase()}}</p>
                </div>
                <div class="boss-email">
                    <h3>Correo: </h3>
                    <a href="mailto:{{tecnico.nombre}}">{{tecnico.correo}}</a>
                </div>
                <div class="boss-phone">
                    <h3>Teléfono: </h3>
                    <a>{{tecnico.telefono}}</a>
                </div>
            </div>
        </div>

    </div>


    <div class="aot-contact-island" id="laspalmas" *ngIf="codigoIsla === 2">
        <div class="aot-contact-island-data">
            <h3>Provincia: <span class="aot-contact-island-data-province">Las Palmas</span></h3>
            <span>Oficinas:

            </span>
            <h3><span>C/ León y castillo, 54, CP: 35003; Las Palmas de Gran Canaria.
                    Teléfono:
                    922887887</span>

            </h3>
            <h3><span>Rambla Medular, 99, piso 2, CP: 35500; Arrecife.
                    Teléfono:
                    922887887</span>

            </h3>
            <h3><span>C/ San Roque, 23, piso 4, local 4F, CP: 35600.
                    Teléfono:
                    922887887</span>

            </h3>

        </div>

        <h2>Responsables de AOT</h2>

        <div class="aot-contact-boss">

            <div class="boss" *ngFor="let responsable of CONTACTOS_AOT_RESPONSABLES_LP">
                <div class="boss-data">
                    <p class="boss-name">{{responsable.nombre}}</p>
                    <p class="boss-job">{{responsable.perfil.toLocaleUpperCase()}}</p>
                </div>
                <div class="boss-email">
                    <h3>Correo: </h3>
                    <a href="mailto:{{responsable.nombre}}">{{responsable.correo}}</a>
                </div>
                <div class="boss-phone">
                    <h3>Teléfono: </h3>
                    <a>{{responsable.telefono}}</a>
                </div>
            </div>
        </div>

        <h2>Personal técnico de AOT</h2>
        <div class="aot-contact-boss">


            <div class="boss" *ngFor="let tecnico of CONTACTOS_AOT_TECNICOS_LP">
                <div class="boss-data">
                    <p class="boss-name">{{tecnico.nombre}}</p>
                    <p class="boss-job">{{tecnico.perfil.toLocaleUpperCase()}}</p>
                </div>
                <div class="boss-email">
                    <h3>Correo: </h3>
                    <a href="mailto:{{tecnico.nombre}}">{{tecnico.correo}}</a>
                </div>
                <div class="boss-phone">
                    <h3>Teléfono: </h3>
                    <a>{{tecnico.telefono}}</a>
                </div>
            </div>
        </div>



    </div>


</div>